import React from 'react';
import Router from "./routeMain.js"




function App(){
  return (
    
    <Router/>
 )
}


export default App;
